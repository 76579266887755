.fin-container {
  padding: 50px 0;

  .header {
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: var(--blue-transparent);
  }
  .main {
    padding: 30px 0;
    .image {
      height: 600px;
    }
    .right {
      padding: 50px 10px 0 0px;
      max-width: 550px;
      .title {
        color: var(--blue-transparent-2);
        font-weight: 700;
        font-size: 25px;
      }
      .description {
        color: var(--blue-transparent);
        margin: 30px 0;
        font-size: 19px;
        line-height: 35px;
      }
      .download {
        background-color: var(--blue-primary);
        border-radius: 50px;
        height: 50px;
        width: 180px;
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .main {
      .image {
        height: unset;
        width: 360px;
        margin-left: 10px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .header {
      text-align: left;
    }
    .main {
      flex-direction: column;
      align-items: flex-start;
      .image {
        width: 320px;
        margin-left: 0px;
      }
      .right {
        width: 100%;
        max-width: unset;
        .title {
          display: none;
        }
        .small-title {
          display: block;
        }
      }
    }
  }
}
.small-title {
  display: none;
}
