.waitlist-container {
  .header {
    font-size: 40px;
    text-align: center;
    width: 100%;
    font-weight: 600;
    color: var(--blue-transparent);
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px 40px 0px;
    .content {
      max-width: 400px;
      width: 100%;
      height: 180px;
      .input {
        font-family: "Open Sans", sans-serif;

        width: 100%;
        height: 37px;
        border-radius: 5px;
        border: 1px solid var(--blue-transparent-3);
        &::placeholder {
          color: var(--blue-transparent-3);
          font-weight: 600;
          font-family: "Open Sans", sans-serif;
        }
      }
      .button {
        background-color: var(--blue-primary);
        border-radius: 50px;
      }
    }
  }
}
