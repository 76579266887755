.app-banner-container {
  padding: 60px 0;
  .main {
    .left {
      background-color: var(--light-purple);
      width: 48%;
      height: 650px;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 0px 30px;

      .content {
        width: 100%;
        .suffix-image {
          height: 90px;
          width: 90px;
        }
        .title {
          color: var(--blue-transparent-2);
          font-weight: 600;
          font-size: 25px;
          margin: 30px 0px;
        }
        .icon {
          height: 18px;
        }
        .download {
          background-color: var(--blue-primary);
          border-radius: 18px;
          height: 50px;
          width: 250px;
          font-size: 14px;
          font-weight: 400;
          margin: 10px 0;
          &:first-child {
            margin-top: 15px;
          }
        }
      }
    }
    .navigator-image {
      height: 650px;
    }
  }
  @media screen and (max-width: 1050px) {
    .main {
      .left {
        height: 600px;
      }
      .navigator-image {
        height: 600px;
        margin-left: 30px;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .main {
      .left {
        height: fit-content;
        width: 100%;
        padding: 30px 30px;
      }
      .navigator-image {
        display: none;
      }
    }
  }
}
