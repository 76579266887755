@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@media print {
  body {
    margin: 0 !important;
  }
}

.main-editor-container {
  font-family: "Lato";
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.ck-content {
  font-family: "Lato";
  line-height: 1.6;
  word-break: break-word;
}

.editor-container_inline-editor .editor-container__editor {
  min-width: 100%;
  //   max-width: 795px;
}

.editor-container_include-block-toolbar {
  margin-left: 20px;
}
