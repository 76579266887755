:root {
  --blue-transparent: rgba(2, 39, 107, 0.8);
  --blue-transparent-2: rgba(2, 39, 107, 0.7);
  --blue-transparent-3: rgba(4, 68, 187, 0.5);
  --blue: rgba(2, 39, 107, 1);
  --blue-primary: rgba(4, 68, 187, 1);
  --blue-secondary: #c1d7fe;
  --blue-tertiary: #2d4d89;
  --blue-white: #eef4ff;
  --light-purple: #dbe8fe;

  --orange-primary: rgba(255, 152, 57, 1);
  --orange-primary-transparent: rgba(255, 152, 57, 0.6);
  --orange-white: #fff6ed;

  --black-primary: #292929;
  --whitish: #f9fcfb;
}
