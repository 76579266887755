.enhanced-diabetes-container {
  padding: 50px 0;

  .header {
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: var(--blue-transparent);
  }
  .items {
    margin-top: 30px;
    .item {
      border-radius: 15px;
      height: 180px;
      padding: 0 20px;
      .image {
        border-radius: 50%;
        height: 140px;
        width: 140px;
      }
      .title {
        color: var(--blue-transparent-2);
        font-size: 22px;
        margin-left: 30px;
        font-weight: 600;
      }
    }
    .item-blue {
      background-color: var(--blue-secondary);
    }
    .item-orange {
      background-color: var(--orange-primary-transparent);
    }
  }
  @media screen and (max-width: 1000px) {
    .header {
      text-align: left;
    }
  }
  @media screen and (max-width: 700px) {
    .items {
      .item {
        .title {
          font-size: 18px !important;
        }
      }
    }
  }
}
